
#home {
    position: relative;
    background: url('../../assets/bgIMG.jpg');
    background-size:cover;
    background-repeat:repeat;
    background-position: center;
    width: 100%;
    border-radius: 2rem;
    padding-bottom: 4rem;

    @media screen and (max-width: 1000px) {
        width: 80%;
        height: 60%;
    }

    .app__wrapper {
        display: none;

     .copyright {
         display: none;
      }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 6rem;

    @media screen and (min-width: 2000px) {
    
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding-top: 6rem;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-bottom: 0rem;
    }

    @media screen and (max-width: 450px) {
    
    }
}

.app__header-info {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 3rem;

    @media screen and (max-width: 2000px) {
        width: 100%;

    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;

    @media screen and (min-width: 3000px) {
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 2rem;


    }

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        margin-right: 1rem;
        margin-left: 1rem;
        background-color: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        @media screen and (min-width: 2000px) {
            margin-top: 2rem;
    
        }


    
    }

    .tag-cmp {
        flex-direction: column;
        p {
            width: 100%;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.25rem;
        }

        @media screen and (max-width: 1200px) {
           margin-top: 3rem;
           font-size: .8rem;
        }
    }

    span {
        font-size: 2.5rem;
        
        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .stuff-tag {
        font-size: 2rem;
        color: var(--secondary-color);

        @media screen and (max-width: 2000px) {
            font-size: 1.5rem;
        }
    }
    
}



.app__header-img {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    padding-bottom: 1rem;
    flex-direction: row;
    margin-top: -8rem;
    margin-right: 8rem;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        margin-top: 0;
        margin-right: 0;
        
    }

    @media screen and (min-width: 1200px) {
        padding-left: 6rem;
        
    }

    @media screen and (min-width: 2000px) {
        padding-left: 20rem;
        
    }

    .app__profile-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        margin-left: 1rem;

        @media (max-width: 1200px) {
              flex-direction: column-reverse;
              margin-top: -2rem;
          }

          @media screen and (min-width: 3000px) {
            margin-left: -6rem;
          }

        a {
        text-decoration: none;
        }

        div {
            padding: 1rem 2rem;
            margin: 2rem;
            background-color: var( --secondary-color);
            border-radius: 15px;
            width: auto;
            text-align: center;
            cursor: pointer;

            h1 {
                color: var(--white-color);
            }

            &:hover {
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
              }

            @media screen and (max-width: 1200px) {
                margin-top: 1rem;
                font-size: .7rem;
            }
        }

        img {
            width: 80%;
            object-fit: contain;

            @media screen and (max-width: 1200px) {
                margin-top: -1rem;
                margin-bottom: 2rem;
                width: 50%;
            }

        }

    }
    // img {
    //     width: 50%;
    //     object-fit: contain;
    //     z-index: 1;

    //     @media screen and (min-width: 2000px) {
    //         padding-bottom: -2rem;
    //         width: 40%;
    //     }

    //     @media screen and (max-width: 1200px) {
    //         width: 60%;
    //         margin-top: -6rem;
    //     }
    // }
     /*   .overlay_circle {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            width: 100%;
            height: 90%;
        }
    */
    .app__header-circles {
        flex: 0.75;
        display: flex;
        flex-direction: column;
        justify-content: space-arund;
        align-items: flex-start;
        width: 100%;

        @media screen and (max-width: 1200px){
            flex-direction: row;
        }

        // @media screen and (max-width: 800px) {
        // margin-top: -8rem;
        // padding-bottom: 4rem;
        // }  
        
        div {
            width: 100px;
            height: 100px;
            border-radius: 20%;
            cursor: pointer;
            

            background: var(--white-color);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

            img {
                width: 80%;
                height: 80%;

                @media screen and (max-width: 1200px){
                    margin-top: .55rem;
                }

            }

        }

        div:nth-child(1) {
            width: 150px;
            height: 150px;
            margin: 2rem;
        }
        div:nth-child(2) {
            width: 150px;
            height: 150px;
            margin: 3rem;
        }
        div:nth-child(3) {
            width: 150px;
            height: 150px;
            margin: 2rem;
        }

        // @media screen and (min-width: 3000px) {
        //     div:nth-child(1) {
        //         width: 250px;
        //         height: 250px;
        //     }
        //     div:nth-child(2) {
        //         width: 250px;
        //         height: 250px;
        //     }
        //     div:nth-child(3) {
        //         width: 250px;
        //         height: 250px;
        //     }
        // }
        
        @media screen and (max-width: 1000px) {
            div:nth-child(1) {
                width: 100px;
                height: 100px;
                margin-left: 4rem;
                margin-right: -1rem;
            }
            div:nth-child(2) {
                width: 100px;
                height: 100px;
            }
            div:nth-child(3) {
                width: 100px;
                height: 100px;
                margin-right: 4rem;
                margin-left: -1rem;
            }
        
        }
    }
}