@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

:root {
  --font-base: 'Montserrat', sans-serif;

  --primary-color: #c9c9d0;
  --secondary-color: #CE4927;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #5f5a5a;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}